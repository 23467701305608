import Projects from "../components/Projects"

const LeftMonitor = () => {
    return (
        <section className='ProjectPage'>
            <Projects />
        </section>
    )
}

export default LeftMonitor