import Contact from "./Contact"

const Footer = () => {
    return (
        <footer className="footer">
            <Contact />
        </footer>
    )
}

export default Footer