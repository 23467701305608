import Resume from "../components/Resume"
import Contact from "../components/Contact"

const RightMonitor = () => {
    return(
        <section className='ResumePage'>
            <Resume />
        </section>
    )
}

export default RightMonitor